html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section {
  display: block;
}

summary {
  display: list-item;
}

audio, canvas, progress, video {
  display: inline-block;
}

progress {
  vertical-align: baseline;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

a {
  background-color: #0000;
}

a:active, a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

figure {
  margin: 1em 40px;
}

img {
  border-style: none;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

button, input, select, textarea, optgroup {
  font: inherit;
  margin: 0;
}

optgroup {
  font-weight: bold;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  color: inherit;
  dispay: table;
  max-width: 100%;
  white-space: normal;
  padding: 0;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="rado"] {
  padding: 0;
}

[type="number"]::-webkit-iner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-apparance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-serch-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-buton {
  -webkit-appearance: button;
  font: inherit;
}

html, body {
  font-family: Verdana, sans-serif;
  font-size: 15px;
  line-height: 1.5;
}

html {
  overflow-x: hidden;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 16px;
}

hr {
  border: 0;
  border-top: 1px solid #eee;
  margin: 20px 0;
}

.w3-right {
  float: right !important;
}

.w3-image {
  max-width: 100%;
  height: auto;
}

img {
  vertical-align: middle;
}

a {
  color: inherit;
}

.w3-btn, .w3-button {
  vertical-align: middle;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  border: none;
  padding: 8px 16px;
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
}

.w3-btn:hover {
  box-shadow: 0 8px 16px #0003, 0 6px 20px #00000030;
}

.w3-btn, .w3-button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.w3-disabled, .w3-btn:disabled, .w3-button:disabled {
  cursor: not-allowed;
  opacity: .3;
}

.w3-disabled *, :disabled * {
  pointer-events: none;
}

.w3-btn.w3-disabled:hover, .w3-btn:disabled:hover {
  box-shadow: none;
}

.w3-input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 8px;
  display: block;
}

.w3-select {
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 9px 0;
}

.w3-dropdown-click, .w3-dropdown-hover {
  cursor: pointer;
  display: inline-block;
  position: relative;
}

.w3-dropdown-hover:hover .w3-dropdown-content {
  display: block;
}

.w3-dropdown-hover:first-child, .w3-dropdown-click:hover, .w3-dropdown-hover:hover > .w3-button:first-child, .w3-dropdown-click:hover > .w3-button:first-child {
  color: #000;
  background-color: #ccc;
}

.w3-dropdown-content {
  cursor: auto;
  color: #000;
  min-width: 160px;
  z-index: 1;
  background-color: #fff;
  margin: 0;
  padding: 0;
  display: none;
  position: absolute;
}

.w3-check, .w3-radio {
  width: 24px;
  height: 24px;
  position: relative;
  top: 6px;
}

.w3-sidebar {
  height: 100%;
  width: 250px;
  z-index: 1;
  background-color: #fff;
  overflow: auto;
  position: fixed !important;
}

.w3-bar-block .w3-dropdown-hover, .w3-bar-block .w3-dropdown-click {
  width: 100%;
}

.w3-bar-block .w3-dropdown-hover .w3-dropdown-content, .w3-bar-block .w3-dropdown-click .w3-dropdown-content {
  min-width: 100%;
}

.w3-bar-block .w3-dropdown-hover .w3-button, .w3-bar-block .w3-dropdown-click .w3-button {
  width: 100%;
  text-align: left;
  padding: 8px 16px;
}

.w3-main, #main {
  transition: margin-left .4s;
}

.w3-modal {
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: #0006;
  padding-top: 100px;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.w3-modal-content {
  width: 600px;
  background-color: #fff;
  outline: 0;
  margin: auto;
  padding: 0;
  position: relative;
}

.w3-bar {
  width: 100%;
  overflow: hidden;
}

.w3-center .w3-bar {
  width: auto;
  display: inline-block;
}

.w3-bar .w3-bar-item {
  float: left;
  width: auto;
  border: none;
  outline: 0;
  padding: 8px 16px;
  display: block;
}

.w3-bar .w3-dropdown-hover, .w3-bar .w3-dropdown-click {
  float: left;
  position: static;
}

.w3-bar .w3-button {
  white-space: normal;
}

.w3-bar-block .w3-bar-item {
  width: 100%;
  text-align: left;
  white-space: normal;
  float: none;
  border: none;
  outline: 0;
  padding: 8px 16px;
  display: block;
}

.w3-bar-block.w3-center .w3-bar-item {
  text-align: center;
}

.w3-block {
  width: 100%;
  display: block;
}

.w3-responsive {
  display: block;
  overflow-x: auto;
}

.w3-container:after, .w3-container:before, .w3-panel:after, .w3-panel:before, .w3-row:after, .w3-row:before, .w3-row-padding:after, .w3-row-padding:before, .w3-clear:after, .w3-clear:before, .w3-bar:before, .w3-bar:after {
  content: "";
  clear: both;
  display: table;
}

.w3-col, .w3-half, .w3-third, .w3-twothird, .w3-threequarter, .w3-quarter {
  float: left;
  width: 100%;
}

.w3-col.s1 {
  width: 8.33333%;
}

.w3-col.s2 {
  width: 16.6667%;
}

.w3-col.s3 {
  width: 25%;
}

.w3-col.s4 {
  width: 33.3333%;
}

.w3-col.s5 {
  width: 41.6667%;
}

.w3-col.s6 {
  width: 50%;
}

.w3-col.s7 {
  width: 58.3333%;
}

.w3-col.s8 {
  width: 66.6667%;
}

.w3-col.s9 {
  width: 75%;
}

.w3-col.s10 {
  width: 83.3333%;
}

.w3-col.s11 {
  width: 91.6667%;
}

.w3-col.s12 {
  width: 100%;
}

@media (min-width: 601px) {
  .w3-col.m1 {
    width: 8.33333%;
  }

  .w3-col.m2 {
    width: 16.6667%;
  }

  .w3-col.m3, .w3-quarter {
    width: 25%;
  }

  .w3-col.m4, .w3-third {
    width: 33.3333%;
  }

  .w3-col.m5 {
    width: 41.6667%;
  }

  .w3-col.m6, .w3-half {
    width: 50%;
  }

  .w3-col.m7 {
    width: 58.3333%;
  }

  .w3-col.m8, .w3-twothird {
    width: 66.6667%;
  }

  .w3-col.m9, .w3-threequarter {
    width: 75%;
  }

  .w3-col.m10 {
    width: 83.3333%;
  }

  .w3-col.m11 {
    width: 91.6667%;
  }

  .w3-col.m12 {
    width: 100%;
  }
}

@media (min-width: 993px) {
  .w3-col.l1 {
    width: 8.33333%;
  }

  .w3-col.l2 {
    width: 16.6667%;
  }

  .w3-col.l3 {
    width: 25%;
  }

  .w3-col.l4 {
    width: 33.3333%;
  }

  .w3-col.l5 {
    width: 41.6667%;
  }

  .w3-col.l6 {
    width: 50%;
  }

  .w3-col.l7 {
    width: 58.3333%;
  }

  .w3-col.l8 {
    width: 66.6667%;
  }

  .w3-col.l9 {
    width: 75%;
  }

  .w3-col.l10 {
    width: 83.3333%;
  }

  .w3-col.l11 {
    width: 91.6667%;
  }

  .w3-col.l12 {
    width: 100%;
  }
}

.w3-content, .w3-auto {
  margin-left: auto;
  margin-right: auto;
}

.w3-content {
  max-width: 980px;
}

.w3-auto {
  max-width: 1140px;
}

.w3-hide {
  display: none !important;
}

.w3-show-block, .w3-show {
  display: block !important;
}

.w3-show-inline-block {
  display: inline-block !important;
}

@media (max-width: 1205px) {
  .w3-auto {
    max-width: 95%;
  }
}

@media (max-width: 600px) {
  .w3-modal-content {
    margin: 0 10px;
    width: auto !important;
  }

  .w3-modal {
    padding-top: 30px;
  }

  .w3-dropdown-hover.w3-mobile .w3-dropdown-content, .w3-dropdown-click.w3-mobile .w3-dropdown-content {
    position: relative;
  }

  .w3-hide-small {
    display: none !important;
  }

  .w3-mobile {
    display: block;
    width: 100% !important;
  }

  .w3-bar-item.w3-mobile, .w3-dropdown-hover.w3-mobile, .w3-dropdown-click.w3-mobile {
    text-align: center;
  }

  .w3-dropdown-hover.w3-mobile, .w3-dropdown-hover.w3-mobile .w3-btn, .w3-dropdown-hover.w3-mobile .w3-button, .w3-dropdown-click.w3-mobile, .w3-dropdown-click.w3-mobile .w3-btn, .w3-dropdown-click.w3-mobile .w3-button {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .w3-modal-content {
    width: 500px;
  }

  .w3-modal {
    padding-top: 50px;
  }
}

@media (min-width: 993px) {
  .w3-modal-content {
    width: 900px;
  }

  .w3-hide-large {
    display: none !important;
  }

  .w3-sidebar.w3-collapse {
    display: block !important;
  }
}

@media (max-width: 992px) and (min-width: 601px) {
  .w3-hide-medium {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .w3-sidebar.w3-collapse {
    display: none;
  }

  .w3-main {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .w3-auto {
    max-width: 100%;
  }
}

.w3-row-padding, .w3-row-padding > .w3-half, .w3-row-padding > .w3-third, .w3-row-padding > .w3-twothird, .w3-row-padding > .w3-threequarter, .w3-row-padding > .w3-quarter, .w3-row-padding > .w3-col {
  padding: 0 8px;
}

.w3-card, .w3-card-2 {
  box-shadow: 0 2px 5px #00000029, 0 2px 10px #0000001f;
}

.w3-card-4, .w3-hover-shadow:hover {
  box-shadow: 0 4px 10px #0003, 0 4px 20px #00000030;
}

.w3-center {
  text-align: center !important;
}

.w3-padding {
  padding: 8px 16px !important;
}

.w3-padding-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.w3-padding-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

/*# sourceMappingURL=index.bb4646e1.css.map */
